.article {
    width: 100%;
    display: grid;
    background-color: hsl(var(--color-white));
    line-height: 0;

    @media (min-width: 1200px) {
        grid-template: [article-start] 'article' 1fr [article-end] / 1fr 1fr;
    }
}

.image {
    display: none;
    position: relative;

    .left & {
        justify-content: flex-end;
    }

    .right & {
        justify-content: flex-start;
    }

    @media (min-width: 1200px) {
        display: flex;
    }
}

.text {
    max-width: 72ch;
    padding: calc(4 * var(--gap));
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    line-height: initial;

    .left & {
        justify-self: center;

        @media (min-width: 1200px) {
            justify-self: flex-start;
        }
    }

    .right & {
        justify-self: center;

        @media (min-width: 1200px) {
            justify-self: flex-end;
        }
    }
}

.heading {
    margin-bottom: var(--gap);
    font-size: var(--font-size-3);
    font-weight: 900;

    @media (min-width: 600px) {
        font-size: var(--font-size-4);
    }

    @media (min-width: 900px) {
        font-size: var(--font-size-5);
    }
}

.paragraph {
    margin-bottom: var(--gap);
    font-size: var(--font-size-0);
    line-height: var(--line-height-md);
}

.footer {
    display: flex;
    align-items: center;
}

.button {
    margin-right: calc(3 * var(--gap));
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
}
